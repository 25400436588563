import React from "react";
import ContentList from "./ContentList";
import "fabric-webpack";
import DesignCanvas from "../components/DesignCanvas";
import Image from "../components/Image";
import img from "../assets/pepper.png";

const Home = () => {
  let width = window.innerWidth;
  let height = window.innerHeight;
  return (
    <section>
      <ContentList
        title={["music videos", "commercial"]}
        content={[
          [
            {
              src: "mus-nineball.mp4",
              song: "nine ball",
              artist: "zach bryan",
              href: "https://player.vimeo.com/video/910178392?portrait=0",
            },
            {
              src: "mus-boysoffaith.mp4",
              song: "boys of faith",
              artist: "zach bryan | bon iver",
              href: "https://player.vimeo.com/video/891565754?portrait=0",
            },
            {
              src: "mus-oklahomasmokeshow.mp4",
              artist: "zach bryan",
              song: "oklahoma smokeshow",
              href: "https://player.vimeo.com/video/822616646?portrait=0",
            },
            {
              src: "mus-driverslicense.mp4",
              artist: "olivia rodrigo",
              song: "driver's license",
              href: "https://player.vimeo.com/video/502269079?portrait=0",
            },
            {
              src: "mus-coldblood.mp4",
              artist: "junior varsity",
              song: "cold blood",
              href: "https://player.vimeo.com/video/532456948?portrait=0",
            },
            {
              src: "mus-worldclasscinema.mp4",
              artist: "gus dapperton",
              song: "world class cinema",
              href: "https://player.vimeo.com/video/296035724?portrait=0",
            },
            {
              src: "mus-pruneyoutalkfunny.mp4",
              artist: "gus dapperton",
              song: "prune, you talk funny",
              href: "https://player.vimeo.com/video/245921641?portrait=0",
            },
            {
              src: "mus-prettylittlefears.mp4",
              artist: "6lack/jcole",
              song: "pretty little fears",
              href: "https://player.vimeo.com/video/295231225?portrait=0",
            },
            {
              src: "mus-cold.mp4",
              artist: "rich brian",
              song: "cold",
              href: "https://player.vimeo.com/video/256605384?portrait=0",
            },
            {
              src: "mus-willhe.mp4",
              artist: "joji",
              song: "will he",
              href: "https://player.vimeo.com/video/239819546?portrait=0",
            },
            {
              src: "mus-crew.mp4",
              artist: "goldlink",
              song: "crew",
              href: "https://player.vimeo.com/video/205822673?portrait=0",
            },
          ],
          [
            {
              artist: "converse all star",
              song: "spring / summer 2023",
              href: "https://player.vimeo.com/video/824855126?portrait=0",
              src: 'com-converse.mp4'
            },
            {
              artist: "facebook",
              song: "deaf hoops",
              href: "https://player.vimeo.com/video/548915948?portrait=0",
              src: "com-facebook.mp4"
            },
            {
              artist: "ralph lauren",
              song: "when gigi met gigi",
              href: "https://player.vimeo.com/video/582252550?portrait=0",
              src: "com-gigi.mp4"
            },
            {
              src: "com-citi.mp4",
              artist: "citi",
              song: "black wall street",
              href: "https://player.vimeo.com/video/680567753?portrait=0",
            },
            {
              src: "com-sky.mp4",
              artist: "skyexpress",
              song: "in with the new",
              href: "https://player.vimeo.com/video/722680647?portrait=0",
            },
            {
              src: "com-converse2.mp4",
              artist: "converse",
              song: "love fearlessly",
              href: "https://player.vimeo.com/video/391507549?portrait=0",
            },
            {
              src: "com-prada.mp4",
              artist: "prada",
              song: "spring / summer '19",
              href: "https://player.vimeo.com/video/337688483?portrait=0",
            },
            {
              src: "com-diamond.mp4",
              artist: "natural diamond council",
              song: "michelle monaghan",
              href: "https://player.vimeo.com/video/477237912?portrait=0",
            },
            {
              src: "com-new-balance.mp4",
              artist: "new balance",
              song: "all terrain",
              href: "https://player.vimeo.com/video/368053478?portrait=0",
            },
            {
              src: "com-nowness.mp4",
              artist: "nowness",
              song: "bulldozer",
              href: "https://player.vimeo.com/video/209694300?portrait=0",
            },
            {
              src: "com-vogue.mp4",
              artist: "vogue",
              song: "gus dapperton",
              href: "https://player.vimeo.com/video/228230434?portrait=0",
            },
            {
              src: "com-adidas.mp4",
              artist: "adidas",
              song: "milan",
              href: "https://player.vimeo.com/video/251199538?portrait=0",
            },
            {
              src: "com-nike.mp4",
              artist: "nike / footlocker",
              song: "before the bite",
              href: "https://player.vimeo.com/video/265476512?portrait=0",
            },
          ],
        ]}
      />
      <DesignCanvas>
        <Image
          url={img}
          scale={0.1}
          top={parseInt(height) - 200}
          left={parseInt(width) - 160}
        />
      </DesignCanvas>

      <div className="footerQuote">
        <span>personality based</span>
        <span>style conscious</span>
        <span>storytelling</span>
      </div>
    </section>
  );
};

export default Home;
