import React, { useState, useRef, useEffect } from "react";


import { useMediaQuery } from "react-responsive";

function ContentList({ title, content }) {
  const [clickedSong, setClickedSong] = useState();
  const [embed, showEmbed] = useState(false);
  const node = useRef();
  const nodeToo = useRef();
  const isMobile = useMediaQuery({ query: "(max-device-width: 800px)" });


  const handleClickOutside = (e) => {
    if (node.current.contains(e.target)) {
      return;
    } else if (nodeToo.current.contains(e.target)) {
      return;
    }
    setClickedSong("");
    showEmbed(false);
  };

  useEffect(() => {
    if (clickedSong) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [clickedSong]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      showEmbed(false);
      let { key } = event;
      if (clickedSong && key === "ArrowDown") {
        if (clickedSong === "music-10") {
          setClickedSong("commercial-0");
        } else if (clickedSong === "commercial-12") {
          setClickedSong("");
        } else {
          let category = clickedSong.split("-")[0];
          let number = clickedSong.split("-")[1];
          setClickedSong(category + "-" + (Number(number) + 1));
        }
      } else if (clickedSong && key === "ArrowUp") {
        if (clickedSong === "music-0") {
          setClickedSong("");
        } else if (clickedSong === "commercial-0") {
          setClickedSong("music-10");
        } else {
          let category = clickedSong.split("-")[0];
          let number = clickedSong.split("-")[1];
          setClickedSong(category + "-" + (Number(number) - 1));
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [clickedSong]);

  let src;
  let href;
  let song;
  let artist;
  switch (clickedSong) {
    case "music-0":
      src = content[0][0].src;
      href = content[0][0].href;
      artist = content[0][0].artist;
      song = content[0][0].song;
      break;
    case "music-1":
      src = content[0][1].src;
      href = content[0][1].href;
      artist = content[0][1].artist;
      song = content[0][1].song;
      break;
    case "music-2":
      src = content[0][2].src;
      href = content[0][2].href;
      artist = content[0][2].artist;
      song = content[0][2].song;
      break;
    case "music-3":
      src = content[0][3].src;
      href = content[0][3].href;
      artist = content[0][3].artist;
      song = content[0][3].song;
      break;
    case "music-4":
      src = content[0][4].src;
      href = content[0][4].href;
      artist = content[0][4].artist;
      song = content[0][4].song;
      break;
    case "music-5":
      src = content[0][5].src;
      href = content[0][5].href;
      artist = content[0][5].artist;
      song = content[0][5].song;
      break;
    case "music-6":
      src = content[0][6].src;
      href = content[0][6].href;
      artist = content[0][6].artist;
      song = content[0][6].song;
      break;
    case "music-7":
      src = content[0][7].src;
      href = content[0][7].href;
      artist = content[0][7].artist;
      song = content[0][7].song;
      break;
    case "music-8":
      src = content[0][8].src;
      href = content[0][8].href;
      artist = content[0][8].artist;
      song = content[0][8].song;
      break;
    case "music-9":
      src = content[0][9].src;
      href = content[0][9].href;
      artist = content[0][9].artist;
      song = content[0][9].song;
      break;
    case "music-10":
      src = content[0][10].src;
      href = content[0][10].href;
      artist = content[0][10].artist;
      song = content[0][10].song;
      break;
    case "commercial-0":
      src = content[1][0].src;
      href = content[1][0].href;
      artist = content[1][0].artist;
      song = content[1][0].song;
      break;
    case "commercial-1":
      src = content[1][1].src;
      href = content[1][1].href;
      artist = content[1][1].artist;
      song = content[1][0].song;
      break;
    case "commercial-2":
      src = content[1][2].src;
      href = content[1][2].href;
      artist = content[1][2].artist;
      song = content[1][2].song;
      break;
    case "commercial-3":
      src = content[1][3].src;
      href = content[1][3].href;
      artist = content[1][3].artist;
      song = content[1][3].song;
      break;
    case "commercial-4":
      src = content[1][4].src;
      href = content[1][4].href;
      artist = content[1][4].artist;
      song = content[1][4].song;
      break;
    case "commercial-5":
      src = content[1][5].src;
      href = content[1][5].href;
      artist = content[1][5].artist;
      song = content[1][5].song;
      break;
    case "commercial-6":
      src = content[1][6].src;
      href = content[1][6].href;
      artist = content[1][6].artist;
      song = content[1][6].song;
      break;
    case "commercial-7":
      src = content[1][7].src;
      href = content[1][7].href;
      artist = content[1][7].artist;
      song = content[1][7].song;
      break;
    case "commercial-8":
      src = content[1][8].src;
      href = content[1][8].href;
      artist = content[1][8].artist;
      song = content[1][8].song;
      break;
    case "commercial-9":
      src = content[1][9].src;
      href = content[1][9].href;
      artist = content[1][9].artist;
      song = content[1][9].song;
      break;
    case "commercial-10":
      src = content[1][10].src;
      href = content[1][10].href;
      artist = content[1][10].artist;
      song = content[1][10].song;
      break;
    case "commercial-11":
      src = content[1][11].src;
      href = content[1][11].href;
      artist = content[1][11].artist;
      song = content[1][11].song;
      break;
    case "commercial-12":
      src = content[1][12].src;
      href = content[1][12].href;
      artist = content[1][12].artist;
      song = content[1][12].song;
      break;
    default:
      break;
  }

  return (
    <section className="contentListWrapper">
      <h2>{title[0]}</h2>
      <div className="list" ref={node}>
        <ul className="artistColumn">
          {Object.keys(content[0]).map(function (key, index) {
            return (
              <li
                key={key}
                className={`${
                  clickedSong === `music-${index}` ? "selected" : ""
                }`}
                onClick={() => {
                  setClickedSong(`music-${index}`);
                  showEmbed(false);
                }}
              >
                {content[0][index].artist}
              </li>
            );
          })}
        </ul>
        <ul className="songColumn">
          {Object.keys(content[0]).map(function (key, index) {
            return (
              <li
                key={key}
                className={`${
                  clickedSong === `music-${index}` ? "selected" : ""
                }`}
                onClick={() => {
                  setClickedSong(`music-${index}`);
                  showEmbed(false);
                }}
              >
                {content[0][index].song}
              </li>
            );
          })}
        </ul>
        {clickedSong && (
          <div className="highlightedVidWrapper">
            {isMobile ? (
              <div className="videoLink" onClick={() => showEmbed(true)}>
                <>
                  <div
                    style={{ padding: "56.25% 0 0 0", position: "relative" }}
                  >
                    <iframe
                      title={song}
                      src={href}
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                      }}
                      frameBorder="0"
                      allow="autoplay; fullscreen"
                      allowFullScreen
                      samesite="None"
                      secure="true"
                    ></iframe>
                  </div>
                  <script src="https://player.vimeo.com/api/player.js"></script>
                </>
                <h3>
                  <span>{artist}</span> <span>{song}</span>
                </h3>
              </div>
            ) : (
              <div className="videoLink" onClick={() => showEmbed(true)}>
                {embed ? (
                  <>
                    <div
                      style={{ padding: "56.25% 0 0 0", position: "relative" }}
                    >
                      <iframe
                        title={song}
                        src={href}
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                        }}
                        frameBorder="0"
                        allow="autoplay; fullscreen"
                        allowFullScreen
                        samesite="None"
                        secure="true"
                      ></iframe>
                    </div>
                    <script src="https://player.vimeo.com/api/player.js"></script>
                  </>
                ) : (
                  <video playsInline muted loop autoPlay key={src}>
                    <source
                    
                    src={require(`../assets/${src}`)}
                    type="video/mp4" />
                  </video>
                )}
                <h3>
                  <span>{artist}</span> <span>{song}</span>
                </h3>
              </div>
            )}
          </div>
        )}
      </div>
      <h2>{title[1]}</h2>
      <div className="list" ref={nodeToo}>
        <ul className="artistColumn">
          {Object.keys(content[1]).map(function (key, index) {
            return (
              <li
                key={key}
                className={`${
                  clickedSong === `commercial-${index}` ? "selected" : ""
                }`}
                onClick={() => {
                  setClickedSong(`commercial-${index}`);
                  showEmbed(false);
                }}
              >
                {content[1][index].artist}
              </li>
            );
          })}
        </ul>
        <ul className="songColumn">
          {Object.keys(content[1]).map(function (key, index) {
            return (
              <li
                key={key}
                className={`${
                  clickedSong === `commercial-${index}` ? "selected" : ""
                }`}
                onClick={() => {
                  setClickedSong(`commercial-${index}`);
                  showEmbed(false);
                }}
              >
                {content[1][index].song}
              </li>
            );
          })}
        </ul>
      </div>
    </section>
  );
}

export default ContentList;
