import React from 'react';
import img from '../assets/matthew-dillon-cohen.jpg';
const MobileContact = () => {

  return (
    <div className="mobileContact">
        <h3>matthew dillon cohen is a filmmaker and photo taker who hates heights, loves bubbling water, and is terrible at writing in the 3rd person. <br /><br />my email is <a href="mailto:m@matthewdilloncohen.nyc">m@matthewdilloncohen.nyc</a><br />please be nice to&nbsp;me.</h3>
        <img src={img} alt="Matthew Dillon Cohen on a couch" />
    </div>
  )
}

export default MobileContact;

